import { Injectable } from '@angular/core';

import { switchMap, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

import { HttpRequestService} from '../../../_services';
import { Observable  } from 'rxjs';
import { BuildUrlBody, SmagUser, Login } from '../../../_models';


const GET_IDENTITIES_ACTION = environment.SmagAccount.getidentities;
const CHECK_SMAG_ACCOUNT = environment.SmagAccount.checksmagaccount;

/* création de l'objet SmagButton pour utilisation dans tout le fichier*/
declare var SmagButton;

@Injectable()
export class SmagAccountService {

   public smag_account_url: string = "";
   public account_exists: boolean;
   public show_close_button;

  private baseUrl = environment.BeApiOperational.url;
  private smagButtonInstance: any;
  private _smag_token:string="";
  private _smag_refresh_token:string="";
  private smagButtonContainerSelector:string =".smag-button-container";


  getSmagUser()
  {
    return JSON.parse(localStorage.getItem('smag_user'));
  }

  setSmagUser(smag_user) {
    localStorage.removeItem('smag_user');
    localStorage.setItem('smag_user', JSON.stringify(smag_user));
  }

  setSmagToken(token:string) {
    this._smag_token = token;
  }

  setSmagRefreshToken(refresh_token: string) {
    this._smag_refresh_token = refresh_token;
  }

   constructor(private httpRequestService: HttpRequestService, protected router: Router){}



   /**
    * Retourne l'url de connexion au compte SMAG
    * @param legacy_id Identifiant legacy de l'utilisateur
    * @returns
    */
   getSmagAccountUrl(data?:any) : Observable<any>{
      let encrypted_user_id:string = '';
      let displaySignUp = "0"; //Afficher l'onglet creation de compte sur l'iframe compte Smag
      let displayLogin = "1"; //Afficher l'onglet connexion sur l'iframe compte Smag

      if (data) {
         this.account_exists = data.account_exists != 'false';
         if (!this.account_exists) {
            encrypted_user_id = data.encrypted_legacy_id;
            displayLogin = "0";
            displaySignUp = "1";
         }
      }
      let body = new BuildUrlBody(environment.SmagAccount.ApplicationId, displaySignUp, displayLogin, encrypted_user_id != '' ? encrypted_user_id: null);

      return this.httpRequestService.PostRequest(environment.SmagAccount.UrlBuildAuth, body, 'getSmagAccountUrl', true)
        .pipe(map(
          (response) => {return response;},
          (error) => {
            throw new Error('Error when getting Smag Account Url');
         })
      )
   }

   getUrl(encrypted_user_id):string {
      if (this.smag_account_url == "") {
         this.getSmagAccountUrl(encrypted_user_id).subscribe((result) => {
            this.smag_account_url = result;
            return result;
         });
      } else {
         return this.smag_account_url;
      }
   }


  // Service message commands
  setShowCloseButton(close_button: boolean) {
    this.show_close_button = close_button;
  }

  //récupération des profils actifs de l'utilisateur
  getActiveUserProfils(smag_token:string, smag_refresh_token:string): any {
      const body = {
         'smag_token': smag_token,
         'smag_refresh_token' : smag_refresh_token,
         'application_id': environment.SmagAccount.ApplicationId,
         'only_active_profil' : true
      };
      return this.httpRequestService.post(this.baseUrl + GET_IDENTITIES_ACTION, body, 'getUsers')
        .pipe(
          map(
            (response) => {
             return response;
          })
        );
    }

  checkCurrentUser() : Observable<any>{
      // Récupération depuis le localStorage
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      let body = {
         'application_id' : environment.SmagAccount.ApplicationId,
         'jarvis_token': currentUser.token
      };
      return this.httpRequestService.post(this.baseUrl + CHECK_SMAG_ACCOUNT, body, 'checkCurrentUser')
         .pipe(map(res => res))
         .pipe(switchMap((data:any) =>
            this.getSmagAccountUrl(data)
         ))
         .pipe(
           map(res => {
            let result = {};
            result['account_exists'] = this.account_exists;
            result['smag_account_url'] = res;
            return result;
         })
      );
   }

   public loadSmagButtonLibraries(){
    let head = document.getElementsByTagName('head')[0];
    let smag_user = this.getSmagUser();
    if (smag_user == null) {
      return;
    }
    this._smag_token = smag_user.smag_token;
    this._smag_refresh_token = smag_user.smag_refresh_token;

    //Check if smagbutton script is already in html head
    if(this.SmagButtonInScript(head))
      return;

    //smagbutton script is not in head...load it
    let smagbuttonscript = document.createElement('script');
    smagbuttonscript.setAttribute('id', 'smagbutton');
    smagbuttonscript.setAttribute('type', 'text/javascript');
    smagbuttonscript.setAttribute('src', environment.SmagAccount.SmagbuttonJs +Date.now());
    smagbuttonscript.onload = this.smagButtonLoadedHandler.bind(this);

    head.appendChild(smagbuttonscript);
  }

  private SmagButtonInScript(header):Boolean{
    for(var i=0; i < header.children.length; i++){
      if(header.children[i].id == "smagbutton")
        return true;
    };
    return false;
  }


  private smagButtonLoadedHandler() {
    var that=this;
    // Construction du SmagButton
    // (containerSelector, providedtoken, refreshtoken, applicationId, alternateCss, keywords, redirectOnLogout)
    const redirectOnLogout = false; // permet de rediriger l'utilisateur sur la page d'accueil
    this.smagButtonInstance = new SmagButton(this.smagButtonContainerSelector, this._smag_token, this._smag_refresh_token, environment.SmagAccount.ApplicationId, true, [], redirectOnLogout );

    this.smagButtonInstance.setButtonStyle(this.getCustomStyle());
    this.smagButtonInstance.addSignOutCallback(this.OnSignOut.bind(that));
    this.smagButtonInstance.addSignInCallback(this.OnSignIn.bind(that));
  }

  /**
   * Déconnexion de l'utilisateur au clic sur le bouton de déconnexion du compte Smag
   */
  public OnSignOut(){
    this._smag_token = "";
    this._smag_refresh_token = "";
    this.clearAsyncSmagAccount().then(() => {
      this.router.navigate(['/login'], {});
    })
  }

  public OnSignIn(){

    if(this.smagButtonInstance){
      this._smag_token = this.smagButtonInstance.token;
      this._smag_refresh_token = this.smagButtonInstance.refresh_token;
      let smag_user = this.getSmagUser();
      smag_user.smag_token = this.smagButtonInstance.token;
      smag_user.smag_refresh_token = this.smagButtonInstance.refresh_token;
      this.setSmagUser(smag_user);
    }
  }
  /*
  * Personnalisation du style du bouton Compte Smag
  *
  */
  private getCustomStyle()
  {
    let custom_style = {};
    let button_style = {};
    button_style['width'] = '60px';
    button_style['height'] = '68px';
    custom_style['Button'] = button_style;
    return custom_style;
  }

  /*
  * Nettoyage du compte Smag
  *
  */
  public clearSmagAccount()
  {
      localStorage.clear();
  }

      /*
  * Nettoyage du compte Smag de manière asynchrone
  *
  */
  public async clearAsyncSmagAccount() : Promise<void>
  {
    return new Promise((resolve, reject) => {
      localStorage.clear();
      resolve();
    });
  }

  setLogins(smag_logins) {
    localStorage.setItem('smag_logins', JSON.stringify(smag_logins));
  }

  getLogins() {
    return JSON.parse(localStorage.getItem('smag_logins'));
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('smag_user'));
  }

  /**
   * Enregistre en session et masque l'iFrame de connexion
   * @param smag_user:SmagUser
   * @param currentUser
   */
  saveLoggedUserInfos(smag_user: SmagUser, currentUser: Login) {
    localStorage.setItem('smag_user', JSON.stringify(smag_user));
    localStorage.setItem('show_smag_iframe', JSON.stringify('false'));
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
  }

  /**
   * Mapping des données utilisateur smag
   * @param smag_user
   * @returns Login
   */
  private MappingUser(smag_user: SmagUser) {
    let oLogin = new Login();
    oLogin.username = smag_user.login;
    oLogin.userid = smag_user.id;
    oLogin.token = smag_user.jarvis_token;
    oLogin.smag_token = smag_user.smag_token;
    return oLogin;
  }

  /**
   * Authentifie l'utilisateur provenant du compte Smag
   * @param smag_user SmagUser utilisateur Compte Smag
   */
  public loginUser(smag_user: SmagUser) {
    this.saveLoggedUserInfos(smag_user, this.MappingUser(smag_user));
    return Promise.resolve(true);
  }
}
